const emojis = {
  ok: "👌",
  no: "👎",
  yes: "👍",
  rocket: "🚀",
  "100": "💯",
  heart: "❤️",
  heart_eyes: "😍",
  heart_broken: "💔",
};

export default {
  beforeRender: function() {
    this.name = this.attrs["name"];
    this.emoji = emojis[this.name];
  },
  render: function() {
    this.innerHTML = this.emoji;
  },
};
